<template>
  <div>
    <v-card class="pa-2 my-3">
      <v-card-title>SMS</v-card-title>
      <v-row no-gutters>
        <v-col>
          <v-card class="mb-2 input-box" flat>
            <v-card-text>
              <label class="subheading">Login:</label>
              <v-text-field v-model="login" :disabled="!editable" hide-details solo-inverted />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mb-2 input-box" flat>
            <v-card-text>
              <label class="subheading">Password:</label>
              <v-text-field v-model="password" :disabled="!editable" hide-details solo-inverted />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mb-2 input-box" flat>
            <v-card-text>
              <label class="subheading">Fornecedor:</label>
              <v-select v-model="provider" :disabled="!editable" :items="smsProviders" outlined hide-details />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mb-2 input-box" flat>
            <v-card-text>
              <label class="subheading">Preço do crédito:</label>
              <v-text-field v-model="price" :disabled="!editable" class="mb-0" type="number" outlined prefix="R$" suffix="/crédito" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <btn-card-actions class="px-2 py-0" :editable.sync="editable" :saving="saving" @save="saveEdit" @cancel="cancelRestoreAccount" />
    </v-card>

    <v-card class="pa-2 my-3">
      <v-card-title>SMS de recuperação de senha</v-card-title>
      <v-row no-gutters>
        <v-col>
          <v-card class="mb-2 input-box" flat>
            <v-card-text>
              <label class="subheading">Login:</label>
              <v-text-field v-model="loginRecovery" :disabled="!editableRecovery" hide-details solo-inverted />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mb-2 input-box" flat>
            <v-card-text>
              <label class="subheading">Password:</label>
              <v-text-field v-model="passwordRecovery" :disabled="!editableRecovery" hide-details solo-inverted />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mb-2 input-box" flat>
            <v-card-text>
              <label class="subheading">Fornecedor:</label>
              <v-select v-model="providerRecovery" :disabled="!editableRecovery" :items="smsProviders" outlined hide-details />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mb-2 input-box" flat>
            <v-card-text>
              <label class="subheading">Preço do crédito:</label>
              <v-text-field v-model="priceRecovery" :disabled="!editableRecovery" class="mb-0" type="number" outlined prefix="R$" suffix="/crédito" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <btn-card-actions class="px-2 py-0" :editable.sync="editableRecovery" :saving="saving" @save="saveEditRecovery" @cancel="cancelRestoreAccountRecovery" />
    </v-card>
  </div>
</template>

<script>
import { MUTATION_UPDATE_SMS_CREDENTIALS, MUTATION_UPDATE_RECOVERY_SMS_CREDENTIALS } from '@/modules/accounts/graphql'

export default {
  components: {
    BtnCardActions: () => import('@/components/base/BtnCardActions')
  },
  props: {
    account: {
      type: Object
    }
  },
  data: () => ({
    accountId: '',
    login: '',
    password: '',
    provider: '',
    price: '',
    loginRecovery: '',
    passwordRecovery: '',
    providerRecovery: '',
    priceRecovery: '',
    editable: false,
    editableRecovery: false,
    saving: false
  }),
  computed: {
    smsProviders() {
      return [
        { text: 'Infobip', value: 'infobip' },
        { text: 'YupChat', value: 'yup' },
        { text: 'YupChat V2', value: 'yup_v2' }
      ]
    }
  },
  watch: {
    account(val) {
      if (val) {
        this.getCredentialsFromAccount(val)
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
    if (this.account) {
      this.getCredentialsFromAccount(this.account)
    }
  },
  methods: {
    getCredentialsFromAccount(adminAccount) {
      this.login = adminAccount?.sms_credentials.login || ''
      this.password = adminAccount?.sms_credentials.password || ''
      this.provider = adminAccount?.sms_credentials.provider || ''
      this.price = +adminAccount?.prices?.credits?.sms || ''
      this.loginRecovery = adminAccount?.password_recovery_sms_credentials?.login || ''
      this.passwordRecovery = adminAccount?.password_recovery_sms_credentials?.password || ''
      this.providerRecovery = adminAccount?.password_recovery_sms_credentials?.provider || ''
      this.priceRecovery = +adminAccount?.prices?.credits?.password_recovery_sms || ''
    },
    async saveEdit() {
      try {
        this.saving = true
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_SMS_CREDENTIALS,
          variables: {
            id: this.accountId,
            login: this.login,
            password: this.password,
            provider: this.provider,
            price: +this.price
          }
        })
        this.editable = false
        this.$snackbar({ message: 'Credenciais SMS atualizadas com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao atualizar Credenciais SMS', snackbarColor: '#F44336' })
      }
      this.saving = false
    },
    async saveEditRecovery() {
      try {
        this.saving = true
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_RECOVERY_SMS_CREDENTIALS,
          variables: {
            id: this.accountId,
            login: this.loginRecovery,
            password: this.passwordRecovery,
            provider: this.providerRecovery,
            price: +this.priceRecovery
          }
        })
        this.editableRecovery = false
        this.$snackbar({ message: 'Credenciais SMS de recuperação atualizadas com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao atualizar Credenciais SMS de recuperação', snackbarColor: '#F44336' })
      }
      this.saving = false
    },
    cancelRestoreAccount() {
      this.$emit('refresh')
      this.editable = false
    },
    cancelRestoreAccountRecovery() {
      this.$emit('refresh')
      this.editableRecovery = false
    }
  }
}
</script>
